import React, { useState } from "react";
import {
  Box,
  BoxProps,
  Link as ChakraLink,
  CloseButton,
  Divider,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  HStack,
  Icon,
  Image,
  Stack,
  Text
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import {
  FiBriefcase,
  FiChevronRight,
  FiDollarSign,
  FiInfo,
  FiMapPin,
  FiSettings,
  FiUser
} from "react-icons/fi";
import {
  FaCalculator,
  FaLocationDot,
  FaMoneyBillTrendUp,
  FaUber,
  FaUserGroup
} from "react-icons/fa6";
import { RiLuggageDepositLine, RiUserLocationLine } from "react-icons/ri";
import { HiOutlineDocument, HiOutlineDocumentReport } from "react-icons/hi";
import { BsCashStack } from "react-icons/bs";

import Link from "next/link";
import { useAuth } from "../../hooks/AuthContext";
import { useRouter } from "next/router";
import {
  FaBuilding,
  FaCar,
  FaChartPie,
  FaClipboardCheck,
  FaHistory,
  FaMoneyCheckAlt,
  FaRegCalendar,
  FaUserCog,
  FaWhatsapp
} from "react-icons/fa";
import { init } from "@sentry/nextjs";
import { BiCoinStack, BiSolidBadgeCheck } from "react-icons/bi";
import { IoCall } from "react-icons/io5";
import { MdOutlineCarRental, MdPayment } from "react-icons/md";
import {
  GrDocument,
  GrDocumentText,
  GrDocumentUser,
  GrMoney
} from "react-icons/gr";
import { TbReportAnalytics, TbReportMoney } from "react-icons/tb";
import path from "path";

interface LinkItemProps {
  name: string;
  icon: IconType;
  href?: any;
  roles: string[];
  initialOpen?: boolean;
  children?: Array<LinkItemProps>;
}

const LinkItems: Array<LinkItemProps> = [
  /*
    ADMIN
  */
  {
    name: "Masters",
    icon: FiUser,
    roles: ["admin"],
    initialOpen: true,
    children: [
      {
        name: "Admins",
        icon: FaUserCog,
        href: "/admins",
        roles: ["admin"]
      },
      {
        name: "Centre Manager",
        icon: RiUserLocationLine,
        href: "/centre-managers",
        roles: ["admin"]
      },
      {
        name: "Drivers",
        icon: FiUser,
        href: "/drivers",
        roles: ["admin"]
      },
      {
        name: "Lease Masters",
        icon: FiDollarSign,
        href: "/lease-masters",
        roles: ["admin"]
      },
      {
        name: "Teams",
        icon: FaUserGroup,
        href: "/teams",
        roles: ["admin"]
      },
      {
        name: "Vendors",
        icon: FiBriefcase,
        href: "/vendors",
        roles: ["admin"]
      },
      {
        name: "Vehicles",
        icon: FaCar,
        roles: ["admin"],
        href: "/vehicles"
      },
      {
        name: "Vehicle Centres",
        icon: FiMapPin,
        roles: ["admin"],
        href: "/centres"
      }
    ]
  },
  {
    name: "Transfer History",
    icon: FaHistory,
    roles: ["admin"],
    href: "/vehicles/transfer-history"
  },
  {
    name: "Inquiries",
    icon: FiInfo,
    roles: ["admin"],
    href: "/inquiries"
  },
  {
    name: "Adjustments",
    icon: BiCoinStack,
    roles: ["admin"],
    href: "/adjustments"
  },
  {
    name: "Transfer Requests",
    icon: FiSettings,
    roles: ["admin"],
    href: "/transfer-requests"
  },
  {
    name: "Payments",
    icon: MdPayment,
    roles: ["admin"],
    href: "/payments"
  },
  {
    name: "Chats",
    icon: FaWhatsapp,
    roles: ["admin"],
    href: "/chats"
  },
  {
    name: "Reports",
    icon: HiOutlineDocumentReport,
    href: "/reports",
    roles: ["admin"],
    children: [
      {
        name: "Documents",
        icon: HiOutlineDocument,
        // href: "/drivers/reports",
        roles: ["admin"],
        href: {
          pathname: "/reports/documents/[slug]",
          query: { slug: "drivers" }
        }
      },
      {
        name: "GPS",
        icon: FaLocationDot,
        roles: ["admin"],
        href: "/reports/gps"
      },
      {
        name: "Daily Reports",
        icon: FaRegCalendar,
        roles: ["admin"],
        children: [
          {
            name: "Uber Reports",
            icon: FaUber,
            roles: ["admin"],
            href: "/vehicles/uber-reports"
          },
          {
            name: "Daily Calling",
            icon: IoCall,
            roles: ["admin"],
            href: "/reports/calling"
          },
          {
            name: "Status",
            icon: FaClipboardCheck,
            roles: ["admin"],
            href: "/reports/status"
          },
          {
            name: "Leasing",
            icon: BsCashStack,
            roles: ["admin"],
            href: "/reports/lease"
          },
          {
            name: "Quality",
            icon: BiSolidBadgeCheck,
            roles: ["admin"],
            href: "/reports/quality"
          }
        ]
      },
      {
        name: "Hisaab & Earnings",
        icon: FaCalculator,
        roles: ["admin"],
        children: [
          {
            name: "Driver Earnings",
            icon: FaMoneyCheckAlt,
            roles: ["admin"],
            href: "/reports/driver-earnings"
          },
          {
            name: "Vehicle Earnings",
            icon: MdOutlineCarRental,
            roles: ["admin"],
            href: "/reports/vehicle-earnings"
          },
          {
            name: "Hisaab",
            icon: FaCalculator,
            roles: ["admin"],
            href: "/reports/hisaab"
          },
          {
            name: "Hisaab Summary",
            icon: TbReportAnalytics,
            roles: ["admin"],
            href: "/reports/hisaab-summary"
          }
        ]
      },
      {
        name: "Outstanding",
        icon: BsCashStack,
        roles: ["admin"],
        children: [
          {
            name: "Driver/Vendor Outstanding",
            icon: FaMoneyBillTrendUp,
            roles: ["admin"],
            href: "/reports/outstanding"
          },
          {
            name: "Company Outstanding",
            icon: FaBuilding,
            roles: ["admin"],
            href: "/reports/company-outstanding"
          }
        ]
      },
      {
        name: "Ledger",
        icon: GrDocument,
        roles: ["admin"],
        children: [
          {
            name: "Account Ledger",
            icon: GrDocumentText,
            roles: ["admin"],
            href: "/reports/account-ledger"
          },
          {
            name: "Driver/Vendor Ledger",
            icon: GrDocumentUser,
            roles: ["admin"],
            href: "/reports/driver-vendor-ledger"
          }
        ]
      },
      {
        name: "JB & SD",
        icon: GrMoney,
        roles: ["admin"],
        children: [
          {
            name: "JB",
            icon: GrMoney,
            roles: ["admin"],
            href: "/reports/vehicle-jb"
          },
          {
            name: "Security Deposit",
            icon: RiLuggageDepositLine,
            roles: ["admin"],
            href: "/reports/security-deposit"
          }
        ]
      },
      {
        name: "Fleet Performance",
        icon: FaCar,
        roles: ["admin"],
        children: [
          {
            name: "Stats",
            icon: FaChartPie,
            roles: ["admin"],
            href: "/reports/stats"
          },
          {
            name: "Company Revenue",
            icon: FaCar,
            roles: ["admin"],
            href: "/reports/company-revenue"
          }
        ]
      }
    ]
  }
];

export default function Sidebar({ isOpen, onOpen, onClose }) {
  return (
    <>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
    </>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const { user } = useAuth();

  return (
    <Box
      transition="3s ease"
      bg="white"
      borderRight="1px"
      borderColor="gray.300"
      w={{ base: "full", md: 64 }}
      pos="fixed"
      color="gray.600"
      pb="4"
      h="full"
      overflowY={"auto"}
      {...rest}
      zIndex={1000}
      bgColor={"white"}
    >
      <Flex
        h="20"
        alignItems="center"
        mx="4"
        justifyContent="center"
        borderBottom="solid 1px"
        borderColor="gray.200"
        bgColor={"white"}
      >
        <Box>
          <Link href="/">
            <Image src="/images/brand-logo.png" w="32" />
          </Link>
          {process.env.NEXT_PUBLIC_NODE_ENV !== "production" && (
            <span
              style={{
                position: "absolute",
                fontSize: "10px",
                paddingTop: "4px",
                paddingBottom: "2px",
                paddingLeft: "5px",
                paddingRight: "5px",
                backgroundColor: "#56cc8e",
                borderRadius: "10px",
                marginLeft: "7.7rem",
                marginTop: "-0.4rem",
                color: "white"
              }}
            >
              DEV
            </span>
          )}
        </Box>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>

      {LinkItems.filter((link) => link.roles.includes(user?.type) || true).map(
        (link) => (
          <NavItem key={link.name} link={link} closeDrawer={onClose} />
        )
      )}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  link: LinkItemProps;
  level?: number;
  closeDrawer?: () => void;
}

const NavItem = ({
  link,
  closeDrawer = null,
  level = 0,
  ...rest
}: NavItemProps) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(link.initialOpen ? true : false);

  let childrenLength = 0;
  if (link.children) {
    childrenLength = link.children.length;
    for (let index = 0; index < link.children.length; index++) {
      const element = link.children[index];
      if (element.children) {
        childrenLength += element.children.length;
      }
    }
  }

  return (
    <Stack spacing="0">
      <ChakraLink
        key={link.name}
        as={link.href ? Link : 'button'}
        href={link.href}
        style={{ textDecoration: "none" }}
        _focus={{ boxShadow: "none" }}
        onClick={(e) => {
          if (closeDrawer) closeDrawer();

          if (link.children && link.children.length > 0) {
            e.preventDefault();
            setIsOpen((current) => !current);
          } 
        }}
        w="full"
      >
        <Flex
          align="center"
          px="3"
          py="3"
          mx="3"
          mt="1"
          borderRadius="lg"
          role="group"
          fontSize="sm"
          color="gray.500"
          fontWeight="medium"
          transition="all 0.25s"
          cursor="pointer"
          {...(router.pathname === link.href
            ? {
                outlineColor: "gray.800",
                color: "gray.800",
                bg: "white"
              }
            : {})}
          outline="solid 1px"
          outlineColor="transparent"
          _hover={{
            color: "gray.800",
            ...(router.pathname != link.href ? {} : { bg: "gray.50" })
            // bg: 'gray.50',
          }}
          {...rest}
        >
          <HStack spacing={0}>
            {Array.from(Array(level).keys()).map((i) => (
              <Box key={i} w="6" opacity={0} />
            ))}
          </HStack>

          {link.children && link.children.length > 0 && (
            <HStack ml={-4}>
              <Text
                transition="all 0.25s"
                transform={isOpen ? "rotate(90deg)" : ""}
              >
                <FiChevronRight />
              </Text>
            </HStack>
          )}

          {link.icon && <Icon mr="4" fontSize="xl" as={link.icon} />}
          <Text align={"left"}>{link.name}</Text>
        </Flex>
      </ChakraLink>

      <Stack
        maxH={isOpen ? `` : "0px"}
        transition="all 0.25s"
        overflow="hidden"
        spacing="0"
        pb="2px"
      >
        {link.children &&
          link.children.length > 0 &&
          link.children.map((child) => (
            <NavItem
              key={child.name}
              link={child}
              closeDrawer={closeDrawer}
              level={level + 1}
            />
          ))}
        {isOpen && <Divider />}
      </Stack>
    </Stack>
  );
};
